// Fix for IE10 and Windows Mobile
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style');
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild(msViewportStyle);
}

// Animated scrolling
$('a[rel="scroll"]').click(function (event) {
    event.preventDefault();
    var scrolloffset = 0;
    var hash = $(this).attr('href');
    $("html, body").animate({scrollTop: $(hash).offset().top - scrolloffset}, 1200);
});

$(document).ready(function () {
    // Mobile nav
    $('.navtoggle').click(function(){
        $('.navtoggle .fa').toggleClass('fa-bars fa-times');
        $('body').toggleClass('nav-overlay');
        $('.mobilenav nav').fadeToggle('fast');
    });

    // Ridderwinkel categorie nav
    $('#categoryselector').click(function(){
       $('#categorycontent').slideToggle();
    });

    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // FitVids
    if ($.isFunction($.fn.fitVids)) {
        $('.fitvids').fitVids();
    }

    // Fancybox
    $(".fancybox").fancybox({
        'loop': false,
        helpers: {
            title: {
                type: 'inside'
            }
        }
    });

    // Wiggling Sword
    $('#sword').addClass("floating");

    $('.order-now').click(function (event) {
        event.preventDefault();
        var scrolloffset = 0;
        $('.order .form-wrapper').slideDown();
        $("html, body").animate({scrollTop: $('.order #form-wrapper-anchor').offset().top - scrolloffset}, 1200);
    });

    $('.products .product .content .info').matchHeight();

    $('.shop.order .item select.amount').on('change', function (e) {
        var target = $(e.target);
        console.log(target.data());
        var entryId = target.data('entry-id'),
            amount = target.val();

        var data = {
            id: entryId,
            amount: amount
        };

        data[window.csrf.name] = window.csrf.value;

        $.post('/actions/shoppingCart/cart/update', data, function (response) {
            var totalProducts = response.total_products;
            $('.product-quantity').text(totalProducts);
            $('.quantity-desc').hide();

            if(totalProducts == 1) {
                $('.multiple-products').hide();
                $('.one-product').show();
            }
            else {
                $('.one-product').hide();
                $('.multiple-products').show();
            }
        });
    });

    // Notice product added to cart
    if(window.location.hash == '#productadded') {
        setTimeout(function () {
            $('.product-added').fadeIn();
        }, 300);
        setTimeout(function () {
            $('.product-added').slideUp();
        }, 7000);
    }

});
